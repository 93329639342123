import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './styles/global.css';
import './styles/iconfont/outlined.css';
import Home from './views/Home';
import AEmpresa from './views/AEmpresa';
import FaleConosco from './views/FaleConosco';
import SejaNossoParceiro from './views/seja-nosso-parceiro'
import NotFound from './views/404';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quem-somos" element={<AEmpresa />} />
      <Route path="/fale-conosco" element={<FaleConosco />} />
      <Route path="/seja-nosso-parceiro" element={<SejaNossoParceiro />} />
      {/* <Route path="/sustentabilidade" element={<Home />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);