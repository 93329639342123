import React from "react";
import "./index.css";
import "../../library/ui/tokens.css";
import Heading from "../../library/ui/components/Heading";
import Menu from "../../library/ui/components/Menu";
import Banner from "../../library/ui/components/Banner";
import Container from "../../library/ui/components/Container";
import Footer from "../../library/ui/components/Footer";
import bannerImg from "./assets/banner.png";
import partnershipImg from "./assets/seja-nosso-parceiro.png";
import contatoImg from "./assets/contato.png";
import aEmpresaImg from "./assets/quem-somos.png";
import ImageLink from "../../library/ui/components/ImageLink";
export const homeCardsData = [
  // {
  //   link: {
  //     href: "#",
  //     children: "Confira nossas dicas para você",
  //   },
  //   image: {
  //     src: dicasImg,
  //     alt: "dicas",
  //   },
  // },
  {
    link: {
      href: "/seja-nosso-parceiro",
      children: "Seja nosso parceiro"
    },
    image: {
      src: partnershipImg,
      alt: "loja",
    },
  },
  {
    link: {
      href: "/quem-somos",
      children: "Quem somos",
    },
    image: {
      src: aEmpresaImg,
      alt: "lorem",
    },
  },
  {
    link: {
      href: "/fale-conosco",
      children: "Fale conosco",
    },
    image: {
      src: contatoImg,
      alt: "contato",
    },
  }
];

function App() {
  return (
    <div className="hbt-Home">
      <Menu />
      <Banner
        data={[
          {
            alt: "",
            src: bannerImg,
            href: "",
            target: "",
            content: (
              <Heading inverse size="xxxl">
                <Heading tag={"span"} size="uul">
                  HB Tintas <br />
                </Heading> A sua loja de tintas.<br />{" "}

              </Heading>
            ),
          },
        ]}
      />
      <article>
        <Container tag={"section"} className={"hbt-Home-cards"}>
          {homeCardsData.map((item, index) => (
            <ImageLink
              key={index}
              link={item.link}
              image={item.image}
            ></ImageLink>
          ))}
        </Container>
      </article>
      <Footer />
    </div>
  );
}

export default App;
