import React from 'react';
import Container from '../Container';
import Text from '../Text';
import './index.css';

export interface FooterProps {
  tag?: React.ElementType
  children?: React.ReactNode
  size?: string
  inverse?: boolean
}

function Footer(props:FooterProps) {

  const Tag = props.tag || 'footer'
  const currentYear = (new Date()).getFullYear();

  return (
    <Tag className={`hbt-Footer hbt-Footer--${props.size||`md`} ${props.inverse?'hbt-Footer--inverse':''}`}>
      <Container>
        <Text inverse bold>&copy; 2007 - {currentYear} HB Tintas. Todos os direitos reservados.</Text>
      </Container>
    </Tag>
  );
}

export default Footer;
