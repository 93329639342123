import React, { useEffect } from 'react';
import Text from '../Text';
import './index.css';

export interface FormProps {
  fields?: Array<FormField>
  id?: string
}

export interface FormField {
  isMandatory?: boolean
  regex?: RegExp
  title: string
  type: string
  errorMessage?: string
  options?: Array<string>
}

function Form(props:FormProps) {

  const { fields, id } = props;

  const initialState:any = {};
  const formDataRegex:any = {};
  const initialErrorCtrlState:any = {};

  const normalizeName = (name:string) => {
    return name.toLowerCase().replace(/\s/g, '-');
  }

  fields?.forEach((item, index) => {
    initialState[normalizeName(item.title)] = ''
    initialErrorCtrlState[normalizeName(item.title)] = false;
    formDataRegex[normalizeName(item.title)] = item.regex;
  })


  const [countFieldJump, setCountFieldJump] = React.useState(false);
  // const [isFormReadyToGo, setIsFormReadyToGo] = React.useState(false);
  const [formData, setFormData] = React.useState(initialState);
  const [errorCtrl, setErrorCtrl] = React.useState(initialErrorCtrlState);
  const [sent, setSent] = React.useState(false);

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    if(sent) return;

    let findErrors = false;
    for (let key in errorCtrl) {
      findErrors = errorCtrl[key];
      if(findErrors) {
        return alert("Por favor, preencha todos os campos obrigatórios.")
      }
    }

    setSent(true);

    const response = await fetch("https://h2zii4q2pb.execute-api.us-east-1.amazonaws.com/default/prd-hbtintas-send-email", {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {'Content-Type': 'application/json; charset=UTF-8'} })
    
    if (!response.ok) {
      alert(`Erro ao enviar os dados. Você será redirecionado para a nossa página "Fale Conosco" para acessar outros canais de contato.`)
      window.location.href="/fale-conosco/";
    } else {
      alert(`Dados recebidos com sucesso! Obrigado.`)
      window.location.href="/";
    }

  }
  
  const handleChange = (e:any) => {
    e.preventDefault();
    let currentData:any = {}
    currentData[e.target.name] = e.target.value;

    setFormData({
      ...formData,
      ...currentData
    })
  }

  const getValue = (name:string) => {
    return formData[name];
  }
  
  const getHasError = (name:string) => {
    return errorCtrl[name];
  }

  const updateErrorCtrl = (e:any) => {
    if(!countFieldJump) {
      setCountFieldJump(true)
    }
    let name = e.target.name;
    let currentErrorCtrl:any = {}

    currentErrorCtrl[name] = !formData[name].match(formDataRegex[name], formData[name])

    setErrorCtrl({
      ...errorCtrl,
      ...currentErrorCtrl
    })

  }

  return (
    <form id={id} className={`hbt-Form`} onSubmit={handleSubmit}>
      {
        fields?.map((item, index) => {
          let itemName:string = normalizeName(item.title);
        return <div className={`hbt-Input hbt-Input--${item.type} ${getHasError(itemName) ? 'hbt-Input--error' : ''}`}>
          <label className='hbt-Input-inputLabel' htmlFor={`input-${index}`}>{item.title}{item.isMandatory?'*':''}:</label>
          {
            item.type === 'select' ? <select className='hbt-Input-inputField' onBlur={updateErrorCtrl} onChange={handleChange} value={getValue(itemName)} id={`input-${index}`} name={itemName}>
              { item.options?.map((optionsItem) => 
                <option value={normalizeName(optionsItem)}>{optionsItem}</option>
            )}
            </select> : <input className='hbt-Input-inputField' onBlur={updateErrorCtrl} onChange={handleChange} value={getValue(itemName)} type={item.type} id={`input-${index}`} name={itemName} />
          }
          <small className='hbt-Input-errorMessage'>{item.errorMessage}</small>
        </div>})
      }
      <div className="hbt-Form-footer">
        <Text>* Campos obrigatórios</Text>
        <button className={`hbt-Form-submit`} type="submit">Enviar</button>
      </div>
    </form>
  );
}

export default Form;
