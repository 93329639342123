import React, { Fragment } from "react";
import "./index.css";
import "../../library/ui/tokens.css";
import Menu from "../../library/ui/components/Menu";
import Banner from "../../library/ui/components/Banner";
import Container from "../../library/ui/components/Container";
import Footer from "../../library/ui/components/Footer";
import bannerImg from "./banner.svg";
import Article from "../../library/ui/components/Article";
import { homeCardsData } from "../Home";
import ImageLink from "../../library/ui/components/ImageLink";

function App() {
  return (
    <div className="hbt-NotFound">
      <Menu />
      <Banner
        data={[
          {
            alt: "",
            src: bannerImg,
            href: "",
            target: "",
            content: "",
          },
        ]}
      />
      <Container tag={"section"} className={"hbt-About-articles"}>
        {[
          {
            title: "404 - Página não encontrada",
            content: [
              "Essa página não existe ou foi removida, mas não se preocupe!",
              "Que tal entrar em contato com a gente?",
            ],
            isSubtitle: false,
          },
        ].map((item, index) => (
          <Article key={index} title={item.title} isSubtitle={item.isSubtitle}>
            {Array.isArray(item.content)
              ? item.content.map((contentItem, contentIndex) => (
                  <Fragment key={contentIndex}>
                    {contentItem}
                    <br />
                  </Fragment>
                ))
              : item.content}
          </Article>
        ))}
      </Container>
      <Container tag={"section"} className={"hbt-Home-cards"}>
        {homeCardsData.map((item, index) => (
          <ImageLink
            key={index}
            link={item.link}
            image={item.image}
          ></ImageLink>
        ))}
      </Container>
      <Footer />
    </div>
  );
}

export default App;
