import React from 'react';
import './index.css';
import { LinkProps } from '../Link';
import Heading from '../Heading';

export interface ImageLinkProps {
  image: ImageLinkImageProps
  link: LinkProps
}

export interface ImageLinkImageProps {
  alt: string
  src: string
}

function ImageLink(props:ImageLinkProps) {

  const { image, link } = props

  return (
    <a className={`hbt-ImageLink`} href={link.href} target={link.target}>
      <img className={`hbt-ImageLink-img`} src={image.src} alt={image.alt} />
      <div className={`hbt-ImageLink-highlight`}>
        <Heading size={'xxs'} inverse >{link.children} <span className='hbt-ImageLink-highlight-icon'>»</span></Heading>
      </div>      
    </a>
  );
}

export default ImageLink;
