import React from 'react';
import './index.css';

export interface TextProps {
  children?: React.ReactNode
  className?: string
  inverse?: boolean
  bold?: boolean
}

function Text(props:TextProps) {

  const {children, inverse, bold, className=`` } = props

  return (
    <p className={`hbt-Text ${className} ${inverse?'hbt-Text--inverse':''} ${bold?'hbt-Text--bold':''}`}>
      {children}
    </p>
  );
}

export default Text;
