import React, { Fragment } from 'react';
import './index.css';
import '../../library/ui/tokens.css';
import Menu from '../../library/ui/components/Menu';
import Banner from '../../library/ui/components/Banner';
import Container from '../../library/ui/components/Container';
import Footer from '../../library/ui/components/Footer';
import Link from '../../library/ui/components/Link';
import bannerImg from './banner.svg';
import Article from '../../library/ui/components/Article';

function App() {
  return (
    <div className="hbt-Contact">
      <Menu />
      <Banner data={[
        {
          alt: '',
          src: bannerImg,
          href: '',
          target: '',
          content: ''
        }
      ]} />
      <article>
        <Container tag={'section'} className={'hbt-About-articles'}>
          {
            [
              {
                title: 'Fale Conosco',
                content: ['Entre em contato pelo nosso WhatsApp, telefone ou por meio do nosso formulário.', 'Se estiver por perto, não deixe de nos fazer uma visitinha, ficaremos felizes em te atender!']
              },
              {
                title: 'Endereços',
                isSubtitle: true,
                content: [
                  <Link href="https://goo.gl/maps/GGsifJywzcfR7FEaA" target={'_blank'} icon={"store"}>Loja Zona Norte: Av. Roland Garros, 1400. Jardim Brasil, São Paulo - SP. CEP 02235-001.</Link>,
                  <Link href="https://goo.gl/maps/M1zf3dq7zewPm18Q9" target={'_blank'} icon={"local_shipping"}>Centro de distribuição: Rua Benfica, 998. Jardim Brasil, São Paulo - SP. CEP 02226-011.</Link>
                ]
              },
              {
                title: 'Telefones',
                isSubtitle: true,
                content: [
                  <Link href="https://api.whatsapp.com/send/?phone=5511930297818&text=Ol%C3%A1%2C+estou+vindo+do+site+da+HB+Tintas+e+gostaria+de+uma+ajudinha+%F0%9F%91%A9%E2%80%8D%F0%9F%8E%A8+%F0%9F%91%A8%E2%80%8D%F0%9F%8E%A8&app_absent=0" target={'_blank'} icon={"whatsapp"}>Atendimento WhatsApp: (11) 9 3029-7818</Link>,
                  <Link href="tel:+551122470438" icon={"phone"}>Atendimento Telefone Zona Norte: (11) 2247-0438</Link>
                ]
              },
              {
                title: 'E-mails',
                isSubtitle: true,
                content: [
                  <Link href="mailto:contato@hbtintas.com.br" icon={"email"}>contato@hbtintas.com.br</Link>
                ]
              },
            ].map((item, index) => <Article tag={'div'} key={index} title={item.title} isSubtitle={item.isSubtitle}>
              {Array.isArray(item.content) ? item.content.map((contentItem, contentIndex) => <Fragment key={contentIndex}>{contentItem}<br/></Fragment>) : item.content}
            </Article>)
          }
        </Container>
      </article>
      <Footer />
    </div>
  );
}

export default App;
