import React from 'react';
import './index.css';

export interface ContainerProps {
  tag?: React.ElementType
  children?: React.ReactNode
  flexSpace?: boolean
  className?: string
}

function Container(props:ContainerProps) {

  const { tag='div', className='' } = props;
  const Tag = tag;

  return (
    <Tag className={`hbt-Container ${className}`}>{props.children}</Tag>
  );
}

export default Container;
