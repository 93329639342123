import React from 'react';
import './index.css';

export interface SubtitleProps {
  tag?: React.ElementType
  children?: React.ReactNode
  size?: string
  inverse?: boolean
}

function Subtitle(props:SubtitleProps) {

  const Tag = props.tag || 'h1'

  return (
    <Tag className={`hbt-Subtitle hbt-Subtitle--${props.size||'md'} ${props.inverse?'hbt-Subtitle--inverse':''}`}>{props.children}</Tag>
  );
}

export default Subtitle;
