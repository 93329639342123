import React from 'react';
import './index.css';

export interface LinkProps {
  tag?: React.ElementType
  children?: React.ReactNode
  icon?: string
  href: string
  target?: string
  className?: string
  inverse?: boolean
  iconAfter?:boolean
}

function Link(props:LinkProps) {

  const {href, target, icon, children, inverse, iconAfter, className=`` } = props

  return (
    <a className={`hbt-Link ${className} ${inverse?'hbt-Link--inverse':''}`} href={href} target={target}>
      {icon && !iconAfter ? <span className={"material-icons hbt-Link-icon " + icon}>{icon}</span> : null}
      <span className="hbt-Link-content">{children}</span>
      {icon && iconAfter ? <span className={"material-icons hbt-Link-icon " + icon}>{icon}</span> : null}
    </a>
  );
}

export default Link;
