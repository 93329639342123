import React from 'react';
import './index.css';

export interface BannerProps {
  data: Array<BannerItemProps>
}

export interface BannerItemProps {
  alt: string
  src: string
  href?: string
  target?: string
  content: React.ReactNode
}

function Banner(props:BannerProps) {

  const { data } = props

  return (
    <div className={`hbt-Banner`}>
      {data.map((item, index) =>
        <div key={index} className={`hbt-Banner-item`}>
          <img className={`hbt-Banner-item-image`} src={item.src} alt={item.alt}/>
          <div className={`hbt-Banner-item-content`}>
            {item.content}
          </div>
        </div>
      )}
    </div>
  );
}

export default Banner;
