import React from 'react';

export interface TopicProps {
  tag?: React.ElementType
  children?: React.ReactNode
  icon?: string
  className?: string
  inverse?: boolean
  iconAfter?:boolean
}

function Topic(props:TopicProps) {

  const {icon, children, inverse, iconAfter, className=`` } = props

  return (
    <p className={`hbt-Topic ${className} ${inverse?'hbt-Topic--inverse':''}`}>
      {icon && !iconAfter ? <span className={"material-icons hbt-Topic-icon " + icon}>{icon}</span> : null}
      <span className="hbt-Topic-content">{children}</span>
      {icon && iconAfter ? <span className={"material-icons hbt-Topic-icon " + icon}>{icon}</span> : null}
    </p>
  );
}

export default Topic;
