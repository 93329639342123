import React, { Fragment } from 'react';
import './index.css';
import '../../library/ui/tokens.css';
import Menu from '../../library/ui/components/Menu';
import Banner from '../../library/ui/components/Banner';
import Container from '../../library/ui/components/Container';
import Footer from '../../library/ui/components/Footer';
import bannerImg from "./assets/banner-a-empresa.png";
import Article from '../../library/ui/components/Article';

function App() {
  return (
    <div className="hbt-About">
      <Menu />
      <Banner data={[
        {
          alt: '',
          src: bannerImg,
          href: '',
          target: '',
          content: ''
        }
      ]} />
      <Container tag={'section'} className={'hbt-About-articles'}>
        {
          [
            {
              title: 'A nossa história',
              content: [
                <>A HB Tintas é uma empresa familiar, fundada em 2008. Nossos valores foram construídos a partir do que acreditamos e principalmente na nossa vontade de deixar o mundo mais alegre, colorido, inclusivo e sobretudo com oportunidades para todos. A loja HB Tintas Matriz fica em uma região de periferia na Zona Norte da capital paulista e o nosso principal público são pessoas que buscam deixar seus lares mais felizes e confortáveis para si ou para as pessoas que ama, seja com uma fachada renovada, a pintura do quarto do bebê que está por vir ou aquela parede preta para deixar o quarto gamer pronto para a jogatina!< br/></>,
                <>Nesses 14 anos de HB Tintas, temos muito orgulho ao imaginar quantas pessoas passaram por aqui, escolhendo tintas, texturas, cores, pedindo dicas e cheias de esperança de pintarem seus sonhos. Fazer parte disso é o que nos move e nos mantém sempre com o foco nos nossos clientes e suas necessidades.< br/></>,
                <>Para definir nossa missão, visão e valores, não poderia ser diferente. Fizemos uma pesquisa com os principais envolvidos com a nossa marca para consolidar o que somos e onde queremos chegar. A HB Tintas quer continuar crescendo, agora não só com um viés corporativo e comercial, mas também como uma porta de oportunidades para quem quer fazer da pintura, uma profissão ou meio de vida.< br/></>
              ]
            },
            {
              title: 'Missão',
              isSubtitle: true,
              content: 'Nossa missão é ter um atendimento que inspire relacionamento para tornar possível a autonomia dos nossos clientes em tornar o seu lar mais bonito e colorido.'
            },
            {
              title: 'Visão',
              isSubtitle: true,
              content: 'Nossa visão é ser a maior loja de tintas da América, apoiada em processos e governança. Diferenciada, apresentando ideias inovadoras, com sistema drive-thru, presença digital estruturada, referência com maior e-commerce e portal de pintura, lider em cursos de capacitação, canais ativos em redes sociais, com conteúdos didáticos e inspiradores e número 1 de quem vai pintar.'
            },
            {
              title: 'Valores',
              isSubtitle: true,
              content: [
                '• Alegria', '• Audição ativa', '• Colaboração', '• Compreensão', '• Comunicação', '• Criatividade', '• Diversidade', '• Educação', '• Eficiência', '• Empatia', '• Ética', '• Higiene', '• Honestidade', '• Humanidade', '• Humildade', '• Justiça', '• Objetividade', '• Organização', '• Paciência', '• Praticidade', '• Prestatividade', '• Prestatividade', '• Progresso', '• Qualidade', '• Respeito', '• Responsabilidade', '• Sustentabilidade'
              ]
            }
          ].map((item, index) => <Article key={index} title={item.title} isSubtitle={item.isSubtitle}>
            {Array.isArray(item.content) ? item.content.map((contentItem, contentIndex) => <Fragment key={contentIndex}>{contentItem}<br/></Fragment>) : item.content}
          </Article>)
        }
      </Container>
      <Footer />
    </div>
  );
}

export default App;
