import React, { Fragment } from 'react';
import './index.css';
import '../../library/ui/tokens.css';
import Menu from '../../library/ui/components/Menu';
import Banner from '../../library/ui/components/Banner';
import Container from '../../library/ui/components/Container';
import Footer from '../../library/ui/components/Footer';
import Topic from '../../library/ui/components/Topic';
import bannerImg from './assets/seja-nosso-parceiro.jpg';
import Article from '../../library/ui/components/Article';
import Form from '../../library/ui/components/Form';

function App() {
  return (
    <div className="hbt-Contact">
      <Menu />
      <Banner data={[
        {
          alt: '',
          src: bannerImg,
          href: '',
          target: '',
          content: ''
        }
      ]} />
      <article>
        <Container tag={'section'} className={'hbt-About-articles'}>
          {
            [
              {
                title: 'Seja um parceiro da HB Tintas e tenha benefícios incríveis!',
                content: [
                  'Na HB Tintas, Arquitetos, Engenheiros, Profissionais de pintura e clientes cadastrados possuem benefícios exclusivos. É muito simples fazer parte! Basta se cadastrar no formulário abaixo.',
                ]
              },
              {
                title: 'Confira os benefícios de fazer parte dessa parceria:'
              },
              {
                title: 'Arquitetos e Engenheiros:',
                isSubtitle: true,
                content: [
                  <Topic icon={"speed"}>Prioridade na entrega</Topic>,
                  <Topic icon={"tune"}>Sistema Self Color (Milhares de cores com alta disponibilidade)</Topic>,
                  <Topic icon={"percent"}>Bonificações</Topic>,
                  <Topic icon={"handshake"}>Indicações para nossos clientes</Topic>
                ]
              },
              {
                title: 'Profissionais de pintura:',
                isSubtitle: true,
                content: [
                  <Topic icon={"school"}>Cursos e Treinamentos</Topic>,
                  <Topic icon={"money"}>Orçamento prioritário</Topic>,
                  <Topic icon={"schedule"}>Prazo de entrega reduzido</Topic>,
                  <Topic icon={"star"}>Promoções Exclusivas</Topic>,
                  <Topic icon={"redeem"}>Brindes</Topic>
                ]
              },
              {
                title: 'Cliente final:',
                isSubtitle: true,
                content: [
                  <Topic icon={"lightbulb"}>Conteúdo do Fica a Dica HB Tintas!</Topic>,
                  <Topic icon={"calculate"}>Calculadora de tinta</Topic>,
                  <Topic icon={"imagesearch_roller"}>Indicação de mão de obra capacitada</Topic>,
                  <Topic icon={"group"}>Acesso aos arquitetos e engenheiros parceiros</Topic>
                ]
              },
              {
                title: 'Preencha o formulário e faça parte!',
                isSubtitle: true
              }
            ].map((item, index) => <Article tag={'div'}  key={index} title={item.title} isSubtitle={item.isSubtitle}>
              {Array.isArray(item.content) ? item.content.map((contentItem, contentIndex) => <Fragment key={contentIndex}>{contentItem}<br/></Fragment>) : item.content}
            </Article>)
          }
          <Form id={'formulario'} fields={[
            {
              title: 'Nome',
              type: 'text',
              isMandatory: true,
              regex: /\w{3}/,
              errorMessage: 'Por favor, preencha pelo menos seu primeiro nome.'
            },
            {
              title: 'Telefone com DDD',
              type: 'phone',
              isMandatory: true,
              regex: /^\d{10}\d?$/,
              errorMessage: 'Por favor, preencha seu telefone corretamente.'
            },
            {
              title: 'E-mail',
              type: 'email',
              isMandatory: true,
              regex: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,
              errorMessage: 'Por favor, preencha o e-mail corretamente.'
            },
            {
              title: 'Motivo',
              type: 'select',
              isMandatory: true,
              regex: /sou-/,
              errorMessage: 'Por favor, nos diga seu interesse na parceria.',
              options: [
                "Selecione",
                "Sou Arquiteto",
                "Sou Engenheiro",
                "Sou Profissional de pintura",
                "Sou Cliente",
              ]
            },
            // {
            //   title: 'Motivo',
            //   type: 'textarea',
            //   isMandatory: false,
            //   regex: /.*/
            // },
          ]} />
        </Container>
      </article>
      <Footer />
    </div>
  );
}

export default App;
