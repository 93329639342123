import React from "react";
import Container from "../Container";
import Link from "../Link";
import "./index.css";
import HbTintasLogo from "./hb-tintas-logo.svg";

export interface MenuProps {
  tag?: React.ElementType;
  children?: React.ReactNode;
  size?: string;
}

export interface navigation {
  href: string;
  target?: string;
  label: string;
  icon?: string;
  isActive?: boolean;
}

function Menu(props: MenuProps) {
  const Tag = props.tag || "header";

  const [isOpen, setOpen] = React.useState(false);
  const [isSmall, setIsSmall] = React.useState(window.innerWidth <= 1024);

  window.addEventListener("resize", () => {
    setIsSmall(window.innerWidth <= 1024);
  });

  const navigation: Array<navigation> = [
    {
      label: `Início`,
      href: `/`,
      isActive:
        window.location.pathname === `/` || window.location.pathname === `/`,
    },
    {
      label: `Seja nosso parceiro`,
      href: `/seja-nosso-parceiro`,
      isActive:
        window.location.pathname === `/seja-nosso-parceiro` ||
        window.location.pathname === `/seja-nosso-parceiro/`,
    },
    {
      label: `Quem somos`,
      href: `/quem-somos`,
      isActive:
        window.location.pathname === `/quem-somos` ||
        window.location.pathname === `/quem-somos/`,
    },
    {
      label: `Fale conosco`,
      href: `/fale-conosco`,
      isActive:
        window.location.pathname === `/fale-conosco` ||
        window.location.pathname === `/fale-conosco/`,
    },
  ];
  const contactNavigation: Array<navigation> = [
    {
      label: `(11) 9 3029-7818`,
      href: `https://api.whatsapp.com/send/?phone=5511930297818&text=Ol%C3%A1%2C+estou+vindo+do+site+da+HB+Tintas+e+gostaria+de+uma+ajudinha+%F0%9F%91%A9%E2%80%8D%F0%9F%8E%A8+%F0%9F%91%A8%E2%80%8D%F0%9F%8E%A8&app_absent=0`,
      icon: `whatsapp`,
      target: "_blank",
    },
    {
      label: `@hb_tintas`,
      href: `https://www.instagram.com/hb_tintas/`,
      icon: `instagram`,
      target: "_blank",
    },
  ];

  return (
    <Tag className={`hbt-Menu`}>
      {window.innerWidth <= 1024 ? (
        <>
          <a href="/">
            <img
              className={`hbt-Menu-left-logo`}
              src={HbTintasLogo}
              alt={`Imagem do logo da HB Tintas: A sua loja de tintas.`}
            />
          </a>
          <button
            onClick={() => setOpen(!isOpen)}
            className="hbt-Menu-toggleButton material-icons hbt-Link-icon"
          >
            {!isOpen ? "menu" : "close"}
          </button>
        </>
      ) : null}
      <Container
        className={`hbt-Menu-container ${isOpen ? "hbt-isActive" : ""}`}
      >
        <div className={`hbt-Menu-left`}>
          {!isSmall ? (
            <>
              <a href="/">
                <img
                  className={`hbt-Menu-left-logo`}
                  src={HbTintasLogo}
                  alt={`Imagem do logo da HB Tintas: A sua loja de tintas.`}
                />
              </a>
            </>
          ) : null}
          <nav>
            <ul>
              {navigation.map((item, index) => (
                <li
                  key={index}
                  className={`hbt-Menu-left-navigation ${
                    item.isActive ? "hbt-isActive" : ""
                  }`}
                >
                  <Link
                    className={`hbt-Menu-left-navigation-link`}
                    href={item.href}
                    target={item.target}
                    icon={item.icon}
                    iconAfter={true}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className={`hbt-Menu-right`}>
          <ul>
            {contactNavigation.map((item, index) => (
              <li key={index} className={`hbt-Menu-left-navigation`}>
                <Link
                  className={`hbt-Menu-left-navigation-link`}
                  href={item.href}
                  target={item.target}
                  icon={item.icon}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Tag>
  );
}

export default Menu;
