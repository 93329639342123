import React from 'react';
import Heading from '../Heading';
import Subtitle from '../Subtitle';
import Text from '../Text';
import './index.css';

export interface ArticleProps {
  title: string
  isSubtitle?: boolean
  tag?: React.ElementType
  children: React.ReactNode
}

function Article(props:ArticleProps) {

  const { tag, children, title, isSubtitle } = props
  const Tag = tag || 'footer'

  return (
    <Tag className={`hbt-Article`}>
      { isSubtitle ? <Subtitle size={'xxxl'}>{title}</Subtitle> : <Heading size={'ul'}>{title}</Heading> }
      <Text className='hbt-Article-content'>{children}</Text>
    </Tag>
  );
}

export default Article;
